/**
 * 高级路由封装
 */
import React from "react";
import { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import type { DataRouteItem } from "./data";
// 导出
export default function RouterView(props: { routes: DataRouteItem[] }) {
    const { routes } = props
    return <Suspense fallback={<div />}>
        <Switch>
            {
                routes.map((route: DataRouteItem) => {
                    if (route.redirect) {
                        return <Route key={route.path}
                            path={route.path}
                            exact={route.exact}
                        >
                            <Redirect to={route.redirect} />
                        </Route>
                    } else {
                        return <Route key={route.path}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                        />
                    }
                })}
        </Switch>
    </Suspense>
}
