import React, { createContext, useContext, useReducer } from 'react';

//? 抛出，创建context上下文，可被访问（通过DataContext.provider的value）
export const DataContext = createContext({});

interface TypeState {
  isSkip: boolean; // 判断是否登录
}

let initState = {
  isSkip: false,
}

//? 抛出，方法可以在普通js中访问
export const dispatchRef: any = (value: any) => {
  return {
    current: value
  };
}


function countReducer(state: TypeState, action: { type: string, value: any }) {
  switch (action.type) {
    case 'changeIsSkip':
      return {
        ...state,
        isSkip: action.value
      }
    default:
      return state
  }
}

//? 抛出，可获取DataContext里的value值
export function useData(){
  const { Xdata } = useContext<any>(DataContext);
  return Xdata;
};


// 主函数（可包裹根组件）
export function GlobalLoadingProvider(props: any) {
  //todo useReducer 接受一个 reducer 函数作为参数，reducer 接受两个参数一个是 state 另一个是 action 。
  //todo 然后返回一个状态 count 和 dispath，count 是返回状态中的值，而 dispatch 是一个可以发布事件来更新 state 的。
  //? 修改dispath后更新dataArr！
  const [dataArr, dispatch] = useReducer(countReducer, initState);
  //? 普通js中访问
  // @ts-ignore
  dispatchRef.current = dispatch;
  return (
    <DataContext.Provider value={{ Xdata: dataArr, dispatch }}>
      {props.children}
    </DataContext.Provider>
  );
}

// @ts-ignore
// let context = React.createContext();

// export {
//   countReducer,
//   initState,
//   context
// } 