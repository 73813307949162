import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useData, DataContext } from '@/reducer/index';
import companyIcon from "@/assets/img/grow-icon.png";
import growicon from "@/assets/video/growicon.mp4";
import styles from "./index.module.less";

const Home = () => {
  const history = useHistory();
  const { dispatch } = useContext<any>(DataContext);
  const state = useData();
  const [growVideoFlag, setGrowVideoFlag] = useState<boolean>(false);
  useEffect(() => {
    if (state.isSkip) {
      setGrowVideoFlag(true);
    }
  }, [state.isSkip])
  return (
    <div className={[styles['home-box']].join(' ')}>
      <div className={styles['home-grow-video-box']}>
        {
          !state.isSkip
            ?
            <video onEnded={() => {
              setGrowVideoFlag(true);
              dispatch({ type: 'changeIsSkip', value: true })
            }} id="grow-video" preload="none" poster={state.isSkip ? companyIcon : ''} className={styles['grow-video']} src={growicon} muted={true} autoPlay={true} />
            :
            <video id="grow-video" preload="none" poster={state.isSkip ? companyIcon : ''} className={styles['grow-video']} src={growicon} muted={true} />
        }
        <div className={[styles['home-btn'], growVideoFlag ? styles.active : ''].join(' ')}>
          <div onClick={() => {
            window.open('https://twitter.com/grown_app')
          }}>FOLLOW US</div>
          <div onClick={() => {
            const href = history.createHref({
              pathname: '/aboutsUs', // 是你想要新窗口打开的路由页面地址
            })
            window.open(href);
          }}>ABOUT US</div>
        </div>
      </div>
    </div>
  )
}

export default Home;