import React, { useState, useRef } from "react";
import { GlobalLoadingProvider } from "./reducer/index";
import startMp4 from "@/assets/video/start.mp4";
import routes from "./router/index";
import RouterView from "./router/RouterView";
import Header from "@/components/Header";
import Bottom from "@/components/Bottom";
import "./App.css";

function App() {
  // const [muted, setMuted] = useState<boolean>(true);
  const [videoFlag, setVideoFlag] = useState<boolean>(true);
  const [blackBgFlag, setBlackBgFlag] = useState<boolean>(true);
  const [skipBtnFlag, setSkipBtnFlag] = useState<boolean>(true);
  const videoRef: any = useRef(null);
  return (
    <GlobalLoadingProvider>
      <div className="App">
        <Header skipBtnFlag={skipBtnFlag} setSkipBtnFlag={setSkipBtnFlag} videoFlag={videoFlag} setVideoFlag={setVideoFlag} setBlackBgFlag={setBlackBgFlag} blackBgFlag={blackBgFlag} />
        <div className="app-content">
          {
            videoFlag
              ?
              <div className={'video-box'}>
                <video id="start-video" ref={videoRef} onTimeUpdate={(e) => {
                  const startVideo: any = document.getElementById('start-video');
                  if (startVideo.currentTime >= 4.73555) {
                    setBlackBgFlag(false);
                  }
                  if (startVideo.currentTime >= 4.23555) {
                    setSkipBtnFlag(false);
                  }
                }} onEnded={() => {
                  setVideoFlag(false);
                }} className={'video-background'} src={startMp4} muted={true} autoPlay={true} />
              </div>
              :
              <RouterView routes={routes} />
          }
        </div>
        <Bottom blackBgFlag={blackBgFlag} />
        {/* {
        (videoFlag && blackBgFlag)
        &&
        <img onClick={() => {
          setMuted(!muted);
        }} className="voice-btn" src={muted ? muteWhiteImg : openVoiceWhiteImg} alt="" />
      }
      {
        (videoFlag && !blackBgFlag)
        &&
        <img className="voice-btn" onClick={() => {
          setMuted(!muted);
        }} src={ muted ? muteBlackImg : openVoiceBlackImg } alt="" />
      } */}
      </div>
    </GlobalLoadingProvider>
  );
}

export default App;
