import React from "react";
import birdImg from "@/assets/img/bird.svg";
import birdWhiteImg from "@/assets/img/bird-white.svg";
import styles from "./index.module.less";

export type PropsBottom = {
  blackBgFlag: boolean;
}

const Bottom = (props: PropsBottom) => {
  const { blackBgFlag } = props;
  return (
    <div className={[styles['bottom-box'], blackBgFlag ? styles.active : ''].join(' ')}>
      <img src={blackBgFlag ? birdWhiteImg : birdImg} alt="" />
      <span onClick={() => {
        window.open('https://twitter.com/grown_app')
      }}>© 2023 Grown, Inc</span>
    </div>
  )
}

export default Bottom;