import React from "react";
import phoneImg from "@/assets/img/phone.png";
import googleImg from "@/assets/img/google.svg";
import downloadImg from "@/assets/img/download-img.svg";
import appstoreImg from "@/assets/img/appstore.svg";
import styles from "./index.module.less";

const Product = () => {
  return (
    <div className={styles['product-box']}>
      <div className={styles['product-box-left']}>
        <div>
          <div className={styles['sub-title']}>
            <img src={downloadImg} alt="" />
          </div>
          <div className={styles['download-list']}>
            <div className={styles['download-option']}>
              <img src={googleImg} alt="" />
              <div className={styles['option-text']}>
                <span>Google Play</span>
                <span>Under development</span>
              </div>
            </div>
            <div className={styles['download-option']}>
              <img src={appstoreImg} alt="" />
              <div className={styles['option-text']}>
                <span>App Store</span>
                <span>Under development</span>
              </div>
            </div>
          </div>
          <div className={styles['download-desc']}>
            The latest product progress and information will be posted on Twitter. Please <span onClick={() => {
              window.open('https://twitter.com/grown_app')
            }}>follow us</span> .
          </div>
        </div>
      </div>
      <img className={styles['phone-img']} src={phoneImg} alt="" />
    </div>
  )
}

export default Product;