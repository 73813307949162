import React from "react";
import growProduct from "@/assets/grow-product.pdf";
import styles from "./index.module.less";
const aboutsUs = () => {
  return (
    <div className={styles['abouts-us-box']}>
      <embed src={growProduct} type="application/pdf" width="100%" height="100%" />
    </div>
  )
}

export default aboutsUs;