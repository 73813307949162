import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DataContext, useData } from '@/reducer/index';
import companyIcon from "@/assets/img/company-icon.svg";
import companyWhiteIcon from "@/assets/img/company-icon-white.svg";

import styles from "./index.module.less";

export type RouteDataType = {
  id: number;
  path: string;
  name: string;
  children: string[]
}

const tabData: RouteDataType[] = [
  {
    id: 1,
    path: '/home',
    name: 'HOME',
    children: ['/home']
  },
  {
    id: 2,
    path: '/product',
    name: 'PRODUCT',
    children: ['/product']
  },
  {
    id: 3,
    path: '/aboutUs',
    name: 'ABOUT US',
    children: ['/aboutUs']
  },
]

export type HeaderProps = {
  blackBgFlag: boolean;
  videoFlag: boolean;
  skipBtnFlag: boolean;
  setBlackBgFlag: (value: boolean) => void;
  setVideoFlag: (value: boolean) => void;
  setSkipBtnFlag: (value: boolean) => void;
}

const Header = (props: HeaderProps) => {
  const { pathname } = useLocation();
  const state = useData()
  const { dispatch } = useContext<any>(DataContext);
  const { blackBgFlag, setBlackBgFlag, setVideoFlag, skipBtnFlag, setSkipBtnFlag } = props;
  const history = useHistory();
  const [currnetTabId, setCurrnetTabId] = useState<number>(1);
  const changeCurrentId = () => {
    const tempData = tabData.find((item) => {
      return item.children.indexOf(pathname) !== -1
    })
    setCurrnetTabId(tempData?.id || 1)
  }
  useEffect(() => {
    if (pathname !== '/home' && pathname !== '/') {
      dispatch({ type: 'changeIsSkip', value: true })
      setVideoFlag(false);
      setBlackBgFlag(false);
      setSkipBtnFlag(false);
    }
    changeCurrentId()
    window.scrollTo(0, 0);
  }, [pathname])
  return (
    <>
      <div className={[styles['header-box'], blackBgFlag ? styles.active : ''].join(' ')}>
        <img className={styles['company-icon']} src={blackBgFlag ? companyWhiteIcon : companyIcon} alt="" />
        <div className={styles['tab-list']}>
          {
            tabData.map((item: RouteDataType) => {
              return (
                <div onClick={() => {
                  if (item.id === 3) {
                    const href = history.createHref({
                      pathname: '/aboutsUs', // 是你想要新窗口打开的路由页面地址
                    })
                    window.open(href);
                    return
                  }
                  history.push(item.path)
                  setCurrnetTabId(item.id);
                  setBlackBgFlag(false);
                  setVideoFlag(false);
                }} key={item.id} className={[styles['tab-option'], currnetTabId === item.id ? styles.active : ''].join(' ')}>
                  {item.name}
                </div>
              )
            })
          }
        </div>
      </div>
      {
        !state.isSkip
        &&
        <div onClick={() => {
          setBlackBgFlag(false);
          setSkipBtnFlag(false);
          setVideoFlag(false);
          dispatch({ type: 'changeIsSkip', value: true })
        }} className={[styles["skip-btn"], !skipBtnFlag ? 'black' : ''].join(' ')}>Skip</div>
      }
    </>
  )
}

export default Header;