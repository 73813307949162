// import { lazy } from "react";
import type { DataRouteItem } from "./data";
import Home from "../pages/home/index";
import Product from "../pages/product/index";
import AboutsUs from "../pages/aboutsUs/index";
const routes: DataRouteItem[] = [
  {
    path: "/",
    exact: true,
    redirect: "/home"
  },
  {
    path: '/home',
    exact: true,
    component: Home
  },
  {
    path: '/product',
    exact: true,
    component: Product,
  },
  {
    path: '/aboutsUs',
    exact: true,
    component: AboutsUs,
  },
];
export default routes;
